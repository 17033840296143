import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { boxShadowHover } from '../global-styles';

import Chevron from '../../../assets/chevron.svg';

const StyledPostNavButton = styled.button`
  ${boxShadowHover}

  display: flex;
  gap: var(--spacer);
  align-items: center;
  outline: inherit;
  padding: var(--spacer-small) var(--spacer);
  border: none;
  color: inherit;
  cursor: pointer;
  max-width: 100vw;

  transition: all 250ms ease-in;

  border-radius: var(--border-radius);
  background-color: var(--glass-bg);
  backdrop-filter: var(--glass-filter);
  box-shadow: var(--glass-glow);
  border: var(--glass-border);

  :hover {
    background: var(--glass-bg-hover);
    box-shadow: var(--glass-glow-hover);
  }
`;

const StyledPostNav = styled.nav`
  display: flex;

  gap: var(--spacer);
  justify-content: center;
  align-self: center;
  flex-wrap: wrap;
  outline: none;
  max-width: calc(var(--content-width) - 2 * var(--spacer));
  margin-bottom: var(--spacer);
`;

const ChevronRight = styled(Chevron)`
  width: 2rem;
  height: auto;
  transform: rotate(90deg);
`;

const ChevronLeft = styled(Chevron)`
  width: 2rem;
  height: auto;
  transform: rotate(-90deg);
`;

export default function Nav({ prev, next }) {
  return (
    <StyledPostNav id="nav">
      {next ? (
        <Link to={next.fields.slug} rel="next">
          <StyledPostNavButton type="button">
            <ChevronLeft />
            <span>{next.frontmatter.title}</span>
          </StyledPostNavButton>
        </Link>
      ) : (
        <div />
      )}
      {prev && (
        <Link to={prev.fields.slug} rel="prev">
          <StyledPostNavButton type="button">
            <span>{prev.frontmatter.title}</span>
            <ChevronRight />
          </StyledPostNavButton>
        </Link>
      )}
    </StyledPostNav>
  );
}
