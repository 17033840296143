import React from 'react';
import styled from 'styled-components';

import Calendar from '../../../assets/calendar.svg';
import Marker from '../../../assets/marker.svg';
import Play from '../../../assets/play.svg';
import Link from '../../../assets/link.svg';
import Github from '../../../assets/github.svg';
import Group from '../../../assets/group.svg';
import Document from '../../../assets/document.svg';

const StyledAside = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-areas: 'icon content';
  gap: var(--spacer);

  > svg {
    width: 2rem;
    height: auto;
    grid-area: icon;
    align-self: flex-start;
  }

  p {
    margin: 0;
    align-self: center;
  }
`;

const GithubBlack = styled(Github)`
  path {
    fill: rgb(var(--text-primary));
  }
`;

const List = styled.ul`
  align-self: center;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const StyledContainer = styled.aside`
  display: flex;
  justify-content: center;
  overflow: hidden;

  position: relative;

  > div {
    display: grid;
    gap: var(--spacer);
    padding: var(--spacer);
    width: 100%;
    max-width: var(--content-width);
    align-self: center;
    background-color: rgb(var(--bg-secondary), .25);
  }
`;

function sortAlphabetically(a, b) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

export function Date({ from, to }) {
  return (
    <StyledAside>
      <Calendar />
      <p>
        <time>{from}</time>
        {to && from !== to && (
          <>
            {' - '}
            <time>{to}</time>
          </>
        )}
      </p>
    </StyledAside>
  );
}

export function Location({ location }) {
  return (
    <StyledAside>
      <Marker />
      <p>{location}</p>
    </StyledAside>
  );
}

export function Team({ team }) {
  return (
    <StyledAside>
      <Group />
      <List>
        {team
          .sort((a, b) => sortAlphabetically(a.name, b.name))
          .map(({ name, link }) => (
            <li key={name}>
              {link ? (
                <a href={link} target="_blank" rel="noreferrer">
                  {name}
                </a>
              ) : (
                name
              )}
            </li>
          ))}
      </List>
    </StyledAside>
  );
}

function LinkList({ icon, list }) {
  return (
    <StyledAside>
      {icon}
      <List>
        {list
          .sort((a, b) => sortAlphabetically(a.title, b.title))
          .map(({ link, title }) => (
            <li key={link}>
              <a href={link} target="_blank" rel="noreferrer">
                {title}
              </a>
            </li>
          ))}
      </List>
    </StyledAside>
  );
}

export function Videos({ videos }) {
  return <LinkList icon={<Play />} list={videos} />;
}

export function Links({ links }) {
  return <LinkList icon={<Link />} list={links} />;
}

export function Repos({ repos }) {
  return <LinkList icon={<GithubBlack />} list={repos} />;
}

export function Documents({ documents }) {
  return (
    <StyledAside>
      <Document />
      <List>
        {documents
          .sort((a, b) => sortAlphabetically(a.title, b.title))
          .map(({ file, title }) => (
            <li key={file.publicURL}>
              <a href={file.publicURL} target="_blank" rel="noreferrer">
                {title}
                {' '}
                -
                {Math.ceil(file.size / 1024)}
                kb
              </a>
            </li>
          ))}
      </List>
    </StyledAside>
  );
}

export default function Aside({
  date,
  location,
  team,
  videos,
  repos,
  documents,
  links,
}) {
  return (
    <StyledContainer id="infos">
      <div>
        <h3>Infos</h3>
        {date && <Date from={date.from} to={date.to} />}
        {location && <Location location={location} />}
        {team && <Team team={team} />}
        {videos && <Videos videos={videos} />}
        {repos && <Repos repos={repos} />}
        {links && <Links links={links} />}
        {documents && <Documents documents={documents} />}
      </div>
    </StyledContainer>
  );
}
