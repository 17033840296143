import React from 'react';
import styled from 'styled-components';

const PostWrapper = styled.article`
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
  padding: var(--spacer);
`;

const MarkDownWrapper = styled.div`
  display: grid;
  border-radius: var(--border-radius);

  * {
    max-width: calc(100vw - 4 * var(--spacer));
    word-wrap: break-word;
  }

  > div  {
    h1 {
      margin-bottom: var(--spacer-small);
    }

    h2 {
      margin-bottom: var(--spacer);
      font-weight: 400;
    }

    blockquote {
      margin: 0;
      padding-left: var(--spacer);
      border-left: 4px solid rgba(var(--bg-primary), 0.33);
    }
  }
`;

const Published = styled.time`
  text-align: right;
  color: rgb(var(--text-primary));
`;

export default function MarkDown({ html, published }) {
  return (
    <PostWrapper id="article">
      <MarkDownWrapper>
        <Published>{published}</Published>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </MarkDownWrapper>
    </PostWrapper>
  );
}
