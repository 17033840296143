import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const GalleryWrapper = styled.article`
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
`;

const StyledGallery = styled.div`
  display: grid;
  border-radius: var(--border-radius);
  padding: var(--spacer);
  max-width: var(--content-width);
  gap: var(--spacer);
  width: 100%;
  overflow: hidden;
`;

const ImgWrapper = styled.div`
  width: 100%;
  border-radius: var(--spacer-small);
  overflow: hidden;
`;

export default function Gallery({ gallery }) {
  return (
    <GalleryWrapper id="gallery">
      <StyledGallery>
        <h3>Gallerie</h3>
        {gallery.map(({ node: { childImageSharp: { id, fluid } } }) => (
          <ImgWrapper key={id}>
            <Img fluid={fluid} />
          </ImgWrapper>
        ))}
      </StyledGallery>
    </GalleryWrapper>
  );
}
