import React from 'react';
import { graphql } from 'gatsby';

import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';

import Aside from '../components/post/asides';
import Nav from '../components/post/nav';
import MarkDownWrapper from '../components/post/markdown';
import Banner from '../components/post/banner';
import Gallery from '../components/post/gallery';

export const pageQuery = graphql`
  query PostBySlug($slug: String!, $gallery: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        published(locale: "de", formatString: "MMMM YYYY")
        description
        banner {
          childImageSharp {
            id
            fluid(maxWidth: 1080) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        location
        documents {
          file {
            size
            publicURL
          }
          title
        }
        date {
          from(formatString: "MMMM YYYY", locale: "de")
          to(locale: "de", formatString: "MMMM YYYY")
        }
        team {
          link
          name
        }
        repos {
          link
          title
        }
        links {
          link
          title
        }
        videos {
          link
          title
        }
      }
    }
    allFile(
      filter: { relativeDirectory: { eq: $gallery } }
      sort: { order: ASC, fields: relativePath }
    ) {
      edges {
        node {
          childImageSharp {
            id
            fluid(maxWidth: 1080) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;

const StyledPost = styled.section`
  max-width: var(--content-width);
  margin-top: var(--spacer);
  border-radius: var(--spacer-small);
  align-self: center;
  overflow: hidden;

  overflow: hidden;

  background: var(--glass-bg);
  border: var(--glass-border);
  box-shadow: var(--glass-glow);
  backdrop-filter: var(--glass-filter);

  margin: var(--spacer);
`;

export default function Post({ data, pageContext, location }) {
  const gallery = data.allFile.edges;
  const { frontmatter, html, excerpt } = data.markdownRemark;
  const {
    title,
    description,
    banner,
    published,
    date,
    location: postLocation,
    team,
    videos,
    repos,
    documents,
    links,
  } = frontmatter;

  const { previous, next } = pageContext;
  const menu = {
    top: true,
    article: true,
    infos: Boolean(
      date || postLocation || team || videos || repos || documents || links,
    ),
    gallery: gallery.length > 0,
    nav: true,
    back: true,
  };

  return (
    <Layout location={location} title="Roman Kollatschny" menu={menu}>
      <SEO title={title} description={description || excerpt} />

      <StyledPost>
        {banner && <Banner banner={banner} />}

        <MarkDownWrapper published={published} html={html} />

        <Aside
          date={date}
          location={postLocation}
          team={team}
          videos={videos}
          repos={repos}
          documents={documents}
          links={links}
        />

        {gallery.length > 0 && <Gallery gallery={gallery} />}
      </StyledPost>

      <Nav next={next} prev={previous} />
    </Layout>
  );
}
