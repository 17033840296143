import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Img from 'gatsby-image';

const ImgWrapper = styled.div`
  box-shadow:
    inset 0 -6px 6px -6px rgb(0 0 0 / 16%),
    inset 0 -3px 3px -6px rgb(0 0 0 / 23%);

  > * {
    max-width: var(--content-width);
    margin: 0 auto;
  }
`;

export default function Banner({ banner }) {
  return (
    <ImgWrapper id="top">
      <Img fluid={banner?.childImageSharp?.fluid} />
    </ImgWrapper>
  );
}
